import { render, staticRenderFns } from "./selectMall.vue?vue&type=template&id=5f513092&scoped=true"
import script from "./selectMall.vue?vue&type=script&lang=js"
export * from "./selectMall.vue?vue&type=script&lang=js"
import style0 from "./selectMall.vue?vue&type=style&index=0&id=5f513092&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f513092",
  null
  
)

export default component.exports