<template>
	<div>

		<head-card>

			<div class="mall-title">请选择要进入的商城后台：</div>
			<div class="mall-list">

				<div class="mall-item" :class="{mallItemIsExpire: item.IsExpire}" v-for="(item,i) in list"
					:key="i" @mouseenter="item.hover=true" @mouseleave="item.hover=false"
					@click="goShop(item)">
					<div class="top">
						<img
							:src="item.MallLogoUrlComplete || 'https://cdn.dkycn.cn/melyshop/image/20211204-3dfb005b-bc09-4473-9416-c37c165b86cb.png'">
						<div class="info">
							<div class="name">{{item.MallName}}</div>
							<div class="time">{{item.ExpireTime.split(' ')[0]}}到期</div>
						</div>
					</div>
					<div class="btn" v-if="item.hover && !item.IsExpire">
						<div>进入商城</div>
					</div>

					<div class="border" v-if="item.hover && !item.IsExpire"></div>

					<div class="expire" v-if="item.IsExpire">
						<span style="opacity: .7;">已到期</span>
						<div>
							<el-button type="text" class="btntoBuy" style="margin-left: 10px;" @click="toBuy(item);$event.cancelBubble=true;">续费</el-button>
						</div>
					</div>
				</div>
			</div>

			<el-dialog title="提示" :visible.sync="visible" width="480px">

				<div class="dialog-tip">
					<div class="icon">
						<i class="el-icon-warning"></i>
					</div>
					<div class="txt">商城【{{mallName}}】已到期，请扫描下方二维码联系企店专属客服，续费成功后，可进入商城</div>
				</div>

				<img src="@/assets/img/20210719171848.jpg" class="dialog-rwm" />

				<span slot="footer" class="dialog-footer">
					<el-button @click="visible = false" size="mini">我知道了</el-button>
				</span>
			</el-dialog>
		</head-card>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex'
	import headCard from "./components/headCard"
	import {
		employeemalllist,
		employeechosemall
	} from '@/api/login'

	export default {
		name: 'login',
		data() {
			return {
				list: [],
				memberName: '',
				memberPhone: '',
				visible: false,
				mallName: '',
			}
		},
		components: {
			headCard
		},
		computed: {
			...mapGetters([
				'loginState',
				'ShopName',
				'ShopLogo',
				'IsXiTai'
			])
		},
		watch: {},

		created() {},
		mounted() {
			this.memberName = localStorage.getItem('mlmzpageName')
			this.memberPhone = localStorage.getItem('mlmzpagePhone')
			this.getMallList();
		},
		methods: {
			torenewal(e) {
				window.localStorage.setItem('MallId', e.MallId || '');
				// console.log(e)
				this.$router.push({
					path: '/versionOrder'
				})
			},
			async getMallList() {
				try {
					const res = await employeemalllist();
					let list = res.Result
					// console.log(list,'list')
					// if(list&&list.length==1&&!list[0].IsExpire){
					// 	this.goShop(list[0])
					// 	return
					// }
					this.list = list.map(item => {
						item.hover = false;
						// item.IsExpire = true
						// console.log(item)
						return item;
					});
				} catch (e) {
					//TODO handle the exception
				}
			},
			async goShop(record) {
				if (record.IsExpire) {
				// console.log(record,33)
					this.toBuy(record)
					// this.visible = true;
					// this.mallName = record.MallName;
					return;
				}

				try {

					this.$store.dispatch('selectMall', {
						MallId: record.MallId
					}).then(() => {
						window.localStorage.setItem('mlmzMallKey', record.MallKey || '');

						this.$router.replace({
							path: '/home'
						})

						// location.replace(location.origin + '/index.html#/home');

						//加个reload 不然路由切换不过来
						// setTimeout(()=>{
						// 	location.reload(false)
						// }, 100)

					}).catch((e) => {
						this.loading = false
					})



				} catch (e) {
					//TODO handle the exception
				}
			},
			logout() {
				this.$store.dispatch('LogOut').then(() => {
					this.$router.replace({
						path: '/login'
					})
				})
			},

			toBuy(e) {
				// console.log(e.MallId)
				window.localStorage.setItem('MallId', e.MallId);
				this.$router.push({
					path: '/versionOrder',
					// query:{
					// 	MallId:e.MallId
					// }
				})
			}
		},
	}
</script>

<style lang="less" scoped>
	.select-mall {
		.mall-info {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100px;
			box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .1);
			display: flex;
			justify-content: center;
			z-index: 1000;
			background-color: #fff;

			.mall-info-wraper {
				display: flex;
				align-items: center;
				justify-content: space-between;

				min-width: 960px;
				max-width: 960px;

				.right {
					font-size: 14px;
					margin-top: 20px;

					span {
						padding-left: 20px;
					}
				}
			}
		}


		.mall-title {
			font-size: 14px;
			// margin-top: 140px;
			margin-left: auto;
			margin-right: auto;
			min-width: 960px;
			max-width: 960px;
		}

		.mall-list {
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;
			min-width: 960px;
			max-width: 960px;

			display: flex;
			flex-wrap: wrap;


			.mall-item {
				position: relative;
				width: 300px;
				height: 150px;
				border: 1px solid #ddd;
				border-radius: 4px;
				padding: 30px 20px;
				box-sizing: border-box;
				margin-right: 20px;
				margin-bottom: 20px;
				overflow: hidden;
				cursor: pointer;
				// border: 1px solid red;
				z-index: 99;

				.top {
					display: flex;

					img {
						flex: 0 0 auto;
						width: 40px;
						height: 40px;
						border-radius: 100%;
					}

					.info {
						flex: 1 1 auto;
						margin-left: 10px;

						.name {
							font-size: 16px;
							margin-top: 2px;
						}

						.time {
							color: #999;
							font-size: 12px;
							margin-top: 5px;
						}
					}
				}

				.btn {
					display: flex;
					justify-content: flex-end;
					color: #409EEF;
					font-size: 14px;
					margin-top: 30px;
				}

				.border {
					position: absolute;
					left: 0;
					width: 100%;
					bottom: 0;
					height: 5px;
					background-color: #409EEF;
				}

				.expire {
					display: flex;
					justify-content: flex-end;
					color: #999;
					font-size: 14px;
					margin-top: 40px;
					align-items: center;
					z-index: 3000;
					.btntoBuy{
						// border:1px solid black;
						position: relative;
					}
				}
			}

			.mallItemIsExpire {

				background-color: #f6f6f6;

				color: #999;

				z-index: 100;
				.top {
					opacity: .7;
					background-color: rgba(246, 246, 246, .7);
					// opacity: .7;
					
				}
			}
		}
	}

	.dialog-tip {
		display: flex;

		.icon {
			flex: 0 0 auto;
			font-size: 16px;
			color: #e7a43c;
			line-height: 22px;
			margin-top: 1px;
			margin-right: 10px;
		}

		.txt {
			flex: 1 1 auto;
			font-size: 14px;
			line-height: 22px;
		}

	}

	.dialog-rwm {
		display: block;
		width: 300px;
		margin: 30px auto 0;
	}
</style>
